export default {
  allUserTypes: state => {
    return state.userTypes;
  },
  rowsTable: state => {
    return [10, 50, 100, "Tout"];
  },
  allProduct: state => {
    return [
      { id: "ABQ", label: "Atlantique Bouclier Quiétude" },
      { id: "APF", label: "Atlantique Prévoyance Familiale" },
      { id: "AHR", label: "Atlantique Horizon Retraite" },
      { id: "AAE", label: "Atlantique Avenir Enfant" },
      { id: "AAF", label: "Tranquilis (Atlantique Assistance Funérailles)" },
    ];
  },
  allFamilySituation: state => {
    return [
      { id: "single", label: "Célibataire" },
      { id: "concubine", label: "Concubin(e)" },
      { id: "divorced", label: "Divorcé(e)" },
      { id: "widower", label: "Veuf(ve)" },
      { id: "married", label: "Marié(e)" },
      { id: "pacse", label: "Pacsé(e)" }
    ];
  },
  allCorrespondenceDate: state => {
    return [
      { id: "day", label: "Jour" },
      { id: "year", label: "Année" },
      { id: "month", label: "Mois" }
    ];
  },
  noticeList: state => {
    return [
      { id: "toStudy", label: "A étudier" },
      { id: "fullInfo", label: "Info Compl" },
      { id: "medicalVisit", label: "Visite medicale" },
      { id: "rejection", label: "Rejet" },
      { id: "standardValidation", label: "Validation standard" },
      { id: "validationWithExtraPremium", label: "Validation avec surprime" }
    ];
  },
  allPaymentStatus: state => {
    return [
      { id: "late", label: "En retard" },
      { id: "required", label: "Non soldé" },
      { id: "payOff", label: "Soldé" }
    ];
  },
  paymentUserTypes: state => {
    return {
      'super_administrator_sys': 'Super Administrateur',
      'administrator_sys': 'Administrateur',
      'gestionnaire_sys': "Gestionnaire",
      'medical_advisor_sys': "Médecin-Conseil",
      'client': 'Client', 
      'broker_inspector': 'Inspecteur',
      'broker_leader': "Chef d'équipe",
      'broker_merchant': 'Commercial',
      'broker_general_agent': 'Agent général',
      'broker': 'Courtier',
      'prospect': 'Prospect',
      'none': "Aucun",
    }
  },
  paymentMethod: state => {
    return [
      { id: "bankCard", label: "Carte bancaire" },
      { id: "check", label: "Chèque" },
      { id: "cash", label: "Espèces" }, 
      { id: "commercialGesture", label: "Geste commercial" },
      { id: "momoCard", label: "Mobile money" },
      { id: "debitAuto", label: "Prélèvement automatique" },
      { id: "virementBank", label: "Virement" },
      { id: "InternalTransfer", label: "Virement interne" }
    ];
  },
  allCategorySocPro: state => {
    return [
      { id: "insuranceAgent", label: "Agent assurance" },
      { id: "executive", label: "Cadre" },
      { id: "entrepreneur", label: "Chef entreprise" },
      { id: "jobSeeker", label: "Demandeur emploi" },
      { id: "employee", label: "Employé" },
      { id: "student", label: "Etudiant" },
      { id: "official", label: "Fonctionnaire" },
      { id: "companyManager", label: "Gérant de société" },
      { id: "worker", label: "Ouvrier" },
      {
        id: "medicalAndParamedicalProfessionsNotEmployees",
        label: "Professions médicales et paramédicales Non Salariés"
      },
      {
        id: "medicalAndParamedicalProfessionsEmployees",
        label: "Professions médicales et paramédicales Salariés"
      },
      { id: "nonSalariedWorker", label: "Travailleur Non Salarié" },
      { id: "retirement", label: "Retraité" },
      { id: "noOccupation", label: "Sans profession" }
    ];
  },
  allStatusOngoing: state => {
    return [
      { id: "quote", label: "Devis" },
      { id: "notTerminate", label: "Non finalisé" },
      { id: "projetNotOk", label: "Sans suite" },
    ]
  },
  statusList: state => {
    return [
      { id: "toStudy", label: "A étudier" },
      { id: "quote", label: "Devis" },
      { id: "projetNotOk", label: "Sans suite" },
      { id: "notTerminate", label: "Non finalisé" },
     // { id: "fullInfo", label: "Info Compl" },
      { id: "medicalVisit", label: "Visite medicale" },
      { id: "rejection", label: "Rejet" },
      { id: "paymentRequired", label: "Paiement requis" },
      { id: "paymentMade", label: "Paiement effectué" }
    ];
  },
  allPeriodicityOfPayment: (state) => {
    return [
      { id: "monthly", label: "Mensuelle" },
      { id: "quarterly", label: "Trimestrielle" },
      { id: "biannual", label: "Semestrielle" },
      { id: "annual", label: "Annuelle" },
    ]
  },
  allMailVariableText: state => {
    let allVariable = "";
    state.allVariableList.forEach(el => {
      allVariable += ` ${el.id}`;
    });
    return `Veuillez utiliser ${allVariable} comme variable dynamique`;
  },
  allMailVariableList: state => {
    return state.allVariableList;
  },
};
